import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../../../hooks/use-actions'
import s from './ShowBags.module.scss'
  import '../../../../styles/slider.css'
import { FaEthereum } from "react-icons/fa";
  import { toast } from 'react-toastify'



  import ReactSlider from 'react-slider'
export default function ShowBags() {

  const bags      = useSelector(state => state.modalSlice.bagsReal)
  const user      = useSelector(state => state.modalSlice.user)

  const { getBagsReal } = useActions()

/*
  const fetchDataAsync = async () => {

    try {

      await getBagsReal(user);

    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    // Initial fetch
    fetchDataAsync();

    // Fetch data every 30 seconds
    const intervalId = setInterval(fetchDataAsync, 30000);

    // Clean up the interval on component unmount
    return () => clearInterval(intervalId);
  }, [user]);

*/
    return (
      <div className={s.container}>
        <CodesList />
      </div>
    )
}

const CodesList = () => {

    const bags = useSelector((state) => state.modalSlice.bagsReal)
    const bags_data = useSelector((state) => state.modalSlice.bags_data)
    const user = useSelector((state) => state.modalSlice.user)

    const { sellToken } = useActions()

    const calculatePercentageDifference = (buyPrice, currentPrice) => {
      
      const difference = currentPrice - buyPrice
      const percentageDifference = (difference / Math.abs(buyPrice)) * 100

      return percentageDifference

    }



    const sellTokenQuery = (token, amount) => {

      console.log('token', token)

      let data = {
        name: token.name,
        symbol: token.symbol,
        address: token.address,
        amount,
        telegram_id: user.telegram_id,
        bag_id: token.bag_id,
        wallet_id: token.wallet_id,
        autobuy_id: token.autobuy_id,
        filter_id: token.filter_id
      }

    toast.success(`Sell of ${amount} ${token.name} initiated`)


      sellToken(data)

    }

    const trimString = (str, maxLength) => {
    
      if (str.length > maxLength) {
        
        return str.substring(0, maxLength) + '...';
      
      }
  
      return str;
  
    }

    const TokenTableRow = ({ rowData }) => {
  const [count, setCount] = useState(0);
  const [amount, setAmount] = useState(0);
  const [profit, setProfit] = useState(0);
      const bagAmount = rowData.tokens * rowData.liquidity_price

      const percentageDifference = calculatePercentageDifference(rowData.spent, bagAmount)
      const percentageDifference2 = calculatePercentageDifference(rowData.amount, bagAmount)

      const calculateTotals = (autosellData) => {

        console.log('autosellData', autosellData)

        let totalReceived = 0;
        let totalAmount = 0;
        let totalSupply = 0;

        autosellData.forEach((autosell) => {
          totalReceived += autosell.received;
          totalAmount += autosell.tokens;
          totalSupply += autosell.supply;
        });

        return {
          totalReceived,
          totalAmount,
          totalSupply,
        };
      }

      let calculatedTotals = {}
      let totalSold = 0
      let totalAmount = 0
      let totalSupply = 0

      if (rowData.autosell !== undefined && rowData.autosell !== null && rowData.autosell.length > 0) {

        // console.log('rowData.autosell.length', rowData.autosell.length)
        calculatedTotals = calculateTotals(rowData.autosell)
        // console.log('calculatedTotals', calculatedTotals)
        totalAmount = parseFloat(calculatedTotals.totalAmount).toFixed(2)
        // totalSold = parseFloat(calculatedTotals.totalReceived).toFixed(2)

/*
        console.log('calculatedTotals.totalReceived', calculatedTotals.totalReceived)
        console.log('calculatedTotals.totalAmount', calculatedTotals.totalAmount)
*/
        totalSold = calculatedTotals.totalReceived
        // console.log('totalSold', totalSold)
      }

      // console.log('calculatedTotals', calculatedTotals)

/*
  const setPercent = (value, token) => {
    
    console.log('a')
   
  }
*/

function convertToSubscriptWithLeadingZeros(num, precision = 18) {
    function convertToFixedString(num, precision) {
        const fixedString = num.toFixed(precision);
        return fixedString.replace(/\.?0+$/, ''); // Remove trailing zeros
    }

    const str = convertToFixedString(num, precision);

    const fractionalPart = str.split('.');
    const [, leadingZeros] = fractionalPart[1].match(/^(0*)([1-9][0-9]*)$/);
    const leadingZerosCount = leadingZeros.length;

    if (leadingZerosCount > 1) {
        const numStr = leadingZerosCount.toString().split('').map(digit => {
            return '₀₁₂₃₄₅₆₇₈₉' [digit - 1];
        }).join('');


        const remainingDigits = fractionalPart[1].substring(leadingZerosCount);

        return `${fractionalPart[0]}.0${numStr}${remainingDigits}`;
    } else {
        return num
    }
}

const convertLiquidityPrice = price => {
  const converted = price.toFixed(50).toString();
  const fractionalPart = converted.split('.');
  const [, leadingZeros] = fractionalPart[1].match(/^(0*)([1-9][0-9]*)$/) || [];
  const leadingZerosCount = leadingZeros ? leadingZeros.length : 0;

  if (leadingZerosCount > 0) {
    const numStr = leadingZerosCount.toString().split('').map(digit => {
      return '₀₁₂₃₄₅₆₇₈₉'[digit];
    }).join('');

    const remainingDigits = fractionalPart[1].substring(leadingZerosCount);

    // Only show the last 4 remaining non-zero digits
    const truncatedDigits = remainingDigits.substring(0, 4);

    return `${fractionalPart[0]}.0${numStr}${truncatedDigits}`;
  } else {
    return price.toString();
  }
}

const calculatePercentage = (x, percentage) => {
  // Calculate the result
  const result = (percentage / 100) * x;

  console.log('result', result)

  setAmount(result)

  return result;
}

function calculatePercentagePNL(buyingPrice, sellingPrice) {
  // Calculate the PNL in percentage
  const pnl = ((sellingPrice - buyingPrice) / buyingPrice) * 100;


  return pnl.toFixed(0)
}

/*
function calculatePercentagePNLTotal(buyingPrice, sellingPrice) {
  // Calculate the PNL in percentage
  let pnl = ((sellingPrice - buyingPrice) / buyingPrice) * 100;

  pnl = pnl + parseFloat(totalSold)

  return pnl.toFixed(0)
}
*/

      return  rowData.autosell !== undefined && rowData.autosell !== null && rowData.autosell.length > 0 ? (

        <tr>
          <td data-label="Profile">
            <span className={s.name}>{rowData.filter_name}</span>
          </td>
          <td data-label="Token">
            <span className={s.name}>{trimString(rowData.name, 20)}</span>
          </td>
          <td data-label="Symbol">
            <span className={s.symbol}>{rowData.symbol}</span>
          </td>
          <td data-label="Address">
            <span className={s.address}>{trimHex(rowData.address)}</span>
          </td>



      <td data-label="Initial Bag">
            <span className={s.bag}>
              {parseFloat(rowData.initial_tokens).toFixed(0)} ${rowData.symbol}
            </span>
          </td>
          
                    <td data-label="Initial Sold">
            <span className={percentageDifference > 0 ? s.green : s.red}>{parseFloat(totalAmount).toFixed(0)} ${rowData.symbol}</span>
          </td>

          <td data-label="Remaining Bag">

                      <span className={s.bag}>
              {parseFloat(rowData.tokens).toFixed(0)} ${rowData.symbol}
            </span>


          </td>

          <td data-label="Entry Price">
            <span className={s.bag}>
              ${rowData.initial_price === null ? 'N/A' : convertLiquidityPrice(parseFloat(rowData.initial_price))}
            </span>
          </td>

               <td data-label="Current Price">
            <span className={s.bag}>
             ${convertLiquidityPrice(parseFloat(rowData.current_price))}
            </span>
          </td>

  <td data-label="Initial Cost">
            <span className={s.bag}>
              {rowData.spent} ETH
            </span>
          </td>


          <td data-label="Realized">
      
            <span className={s.current}>{totalSold.toFixed(4)} ETH</span>
          </td>


          <td data-label="Unrealized">
              <span className={percentageDifference > 0 ? s.green : s.red}>+{calculatePercentagePNL(rowData.spent, (rowData.tokens * rowData.liquidity_price))}%</span> / 
                <span className={s.current}> {bagAmount.toFixed(2)} ETH </span> 
                      

          </td>
{/**
          <td data-label="Total Value">
            <span className={s.current}> {(parseFloat(bagAmount) + parseFloat(totalSold)).toFixed(2)} ETH </span> 
                        [<span className={percentageDifference > 0 ? s.green : s.red}>+{calculatePercentagePNLTotal(rowData.spent, (rowData.tokens * rowData.liquidity_price))}%</span>]

          </td>

**/}

  <td>

  <div style={{display: 'flex', justifyContent: 'space-between'}}>
  <div className='rangeslider' style={{ display: 'flex', border: 'solid 1px #fff' }}>
    <div
      style={{
        width: '145px',
        height: '25px',
        paddingLeft: '5px',
        paddingRight: '5px',
        marginRight: '10px',
      }}
    >
      <ReactSlider
        className='customSlider'
        style={{top: '2px'}}
        thumbClassName='customSlider-thumb'
        trackClassName='customSlider-track'
        markClassName='customSlider-mark'
        min={0}
        max={100}
        defaultValue={0}
        value={rowData.percent}
        onChange={(value) => {

          setCount(value)

          calculatePercentage(rowData.tokens, value)
          // console.log('rowData.tokens', rowData.tokens)
          // console.log(value)

        }}
        renderMark={(props) => {
          if (props.key < rowData.percent) {
            props.className =
              'customSlider-mark customSlider-mark-before';
          } else if (props.key === rowData.percent) {
            props.className =
              'customSlider-mark customSlider-mark-active';
          }
          return <span {...props} />;
        }}
      />
    </div>

    <div
      style={{
        width: '75px',
        borderLeft: '1px solid grey',
        padding: '3px 5px',
        color: 'white',
        position: 'relative',
        top: '2px',
        textAlign: 'center'
      }}
    >
      {count}% BAG
    </div>
  </div>

  <button
    style={{
      border: 'solid 2px rgb(220 142 3)',
      background: 'rgb(246, 174, 45)',
      borderRadius: '5px',
      textAlign: 'center',
      color: '#000',
      fontSize: '12px',
      padding: '5px 10px',
      width: '120px'
    }}

    onClick={() => sellTokenQuery(rowData, amount)}
  >
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontWeight: 'bold'
      }}
    >
      <div>SELL</div>
      <div>
        {(amount * rowData.liquidity_price).toFixed(2)} ETH
      </div>
    </div>
  </button>
  </div>
</td>
        </tr>
              ) : (

        <tr>
          <td data-label="Profile">
            <span className={s.name}>{rowData.filter_name}</span>
          </td>
          <td data-label="Token">
            <span className={s.name}>{trimString(rowData.name, 20)}</span>
          </td>
          <td data-label="Symbol">
            <span className={s.symbol}>{rowData.symbol}</span>
          </td>
          <td data-label="Address">
            <span className={s.address}>{trimHex(rowData.address)}</span>
          </td>



      <td data-label="Initial Bag">
            <span className={s.bag}>
              {parseFloat(rowData.initial_tokens).toFixed(0)} ${rowData.symbol}
            </span>
          </td>

          <td data-label="Initial Price">
            <span className={s.bag}>
              ${rowData.initial_price === null ? 'N/A' : convertLiquidityPrice(parseFloat(rowData.initial_price))}
            </span>
          </td>

    


               <td data-label="Current Price">
            <span className={s.bag}>
             ${convertLiquidityPrice(parseFloat(rowData.current_price))}
            </span>
          </td>

          <td data-label="Initial Cost">
            <span className={s.bag}>
              {rowData.spent} ETH
            </span>
          </td>

          <td data-label="Estimated Value">
            <span className={s.current}>  <span className={percentageDifference > 0 ? s.green : s.red}>+{calculatePercentagePNL(rowData.spent, (rowData.tokens * rowData.liquidity_price))}% / {bagAmount.toFixed(2)} ETH </span> 
                      </span>

          </td>
  



   <td>

  <div style={{display: 'flex', justifyContent: 'space-between'}}>
  <div className='rangeslider' style={{ display: 'flex', border: 'solid 1px #fff' }}>
    <div
      style={{
        width: '145px',
        height: '25px',
        paddingLeft: '5px',
        paddingRight: '5px',
        marginRight: '10px',
      }}
    >
      <ReactSlider
        className='customSlider'
        style={{top: '2px'}}
        thumbClassName='customSlider-thumb'
        trackClassName='customSlider-track'
        markClassName='customSlider-mark'
        min={0}
        max={100}
        defaultValue={rowData.percent}
        value={rowData.percent}
        onChange={(value) => {

          setCount(value)

          calculatePercentage(rowData.tokens, value)
          // console.log('rowData.tokens', rowData.tokens)
          // console.log(value)

        }}
        renderMark={(props) => {
          if (props.key < rowData.percent) {
            props.className =
              'customSlider-mark customSlider-mark-before';
          } else if (props.key === rowData.percent) {
            props.className =
              'customSlider-mark customSlider-mark-active';
          }
          return <span {...props} />;
        }}
      />
    </div>

    <div
      style={{
        width: '75px',
        borderLeft: '1px solid grey',
        padding: '3px 5px',
        color: 'white',
        position: 'relative',
        top: '2px',
        textAlign: 'center'
      }}
    >
      {count}% BAG
    </div>
  </div>

  <button
    style={{
      border: 'solid 2px rgb(220 142 3)',
      background: 'rgb(246, 174, 45)',
      borderRadius: '5px',
      textAlign: 'center',
      color: '#000',
      fontSize: '12px',
      padding: '5px 10px',
      width: '120px'
    }}

    onClick={() => sellTokenQuery(rowData, amount)}

  >
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        fontWeight: 'bold'
      }}
    >
      <div>SELL</div>
      <div>
        {(amount * rowData.liquidity_price).toFixed(2)} ETH
      </div>
    </div>
  </button>
  </div>
</td>



        </tr>

              )

        
    }

    const trimHex = (hexString, prefixLength = 8, suffixLength = 8) => {

      const prefix = hexString.slice(0, prefixLength)
      const suffix = hexString.slice(-suffixLength)
      const trimmedHex = `${prefix}...${suffix}`
      
      return trimmedHex

    }

    const tableClasses = `${s.table} ${s.responsive}`

    let rowData = {
        
        percent: 100

    }

    return (

      <div>
      
        <span className="title">AutoBuy Bags</span><br />
     
        {(bags === undefined || bags.length === 0) && <h3>No auto buy bags found yet</h3>}

        <table className={tableClasses}>
          <thead></thead>
          <tbody>
            {(bags !== undefined && bags.length > 0) && bags.map((rowData, index) => (
              <TokenTableRow key={index} rowData={rowData} />
            ))}
          </tbody>
        </table>

      </div>

    )
}