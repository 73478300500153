import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../../../hooks/use-actions'
import { toast } from 'react-toastify'

import Button from '../../../ui/Button/Button'
import s from './GenerateCode.module.scss'
import { CopyToClipboard } from "react-copy-to-clipboard";

export default function GenerateCode() {
  const { generateCode } = useActions()
   const [textToCopy, setTextToCopy] = useState('Text to copy');
  const [isCopied, setIsCopied] = useState(false);
  const referral_code = useSelector((state) => state.modalSlice.referral_code)

    const handleCopyToClipboard = () => {
    // Create a text area element to hold the text to be copied
    const textArea = document.createElement('textarea');
    textArea.value = `https://t.me/CheckmateTools_Bot?start=${referral_code}`;

    // Append the text area element to the document
    document.body.appendChild(textArea);

    // Select the text within the text area and copy it to the clipboard
    textArea.select();
    document.execCommand('copy');

    // Remove the text area element from the document
    document.body.removeChild(textArea);

    // Set the state to indicate that the text has been copied
    setIsCopied(true);
  };
  return (
    <div className={s.container} style={{textAlign: 'center'}}>
                  <div className={s.codesListItemUsersTitle} className="header" style={{textAlign: 'center'}}>Referred Users</div><br />

      <Button onClick={handleCopyToClipboard}>{isCopied ? 'Link copied to clipboard' : 'Copy Code'}</Button>
      <CodesList />
    </div>
  )
}

const CodesList = () => {
  const referred = useSelector((state) => state.modalSlice.referred)
  const referrer_address = useSelector((state) => state.modalSlice.referrer_address)
  const _user = useSelector((state) => state.modalSlice.user)
  const { saveReferrer } = useActions()

  console.log('referred', referred)
  console.log('_user', _user)
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSaveClick = () => {

    console.log('Input Value:', inputValue);

    let user = {
      telegram_id: _user.telegram_id,
      address: inputValue
    }

    saveReferrer(user)

          toast.success('Address Saved!', {
        position: toast.POSITION.BOTTOM_RIGHT,
      })
  }



  return (
    <div className={s.codes}>


              <ul style={{listStyleType: 'none', paddingLeft: 0}}>
                {referred.map((user, userIdx) => (
                  <li key={user.telegram_id} style={{display: 'block'}}>
                    <div style={{float: 'left', width: '100%'}}>
                      <span style={{float: 'left', display: 'block'}}>{userIdx + 1}. {user.telegram_name}</span>
                      <div style={{float: 'right'}}>{user.noob ? 'inactive' : 'active'}</div>
                    </div>
                  </li>
                ))}

              </ul>
    

                {referrer_address === null && referred.length > 10 ? 
                  <div style={{marginTop: '35px', float: 'left', textAlign: 'left'}}>
                    You are a true $CMBOT CHAD as you have referred more than 10 users.<br /> <br /> Thank you for your support.<br /><br />Enter your wallet address and we'll get you whitelisted on our claims contract.<br /><br />

                    <div>
                      <input style={{background: '#000', color: 'white', float: 'left', border: 'solid 1px rgb(246, 174, 45)', padding: '10px', width: '100%'}}
                        type="text"
                        value={inputValue}
                        onChange={handleInputChange}
                        onClick={() => handleSaveClick}
                        placeholder="ETH ADDRESS"
                      />

                      <button style={{color: '#000', padding: '10px', float: 'right', background: 'rgb(246, 174, 45)', borderRadius: '4px', width: '100%', marginTop: '15px'}} onClick={handleSaveClick}>SAVE ADDRESS</button>
                    </div>

                  </div>

                  : <span style={{textAlign: 'left', paddingTop: '15px'}}>Address saved! Please visit the claims page on our website</span>
                }

    </div>
  )
}
