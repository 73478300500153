import axios from 'axios'

const instance = axios.create({
  baseURL: 'url'
})

class FiltersService {
  async getAll(data) {
    return instance.post(`https://eth.oculus.bot/api/getUser`, data).then((res) => res.data)
  }

  async addQuery(data) {

    return instance.post(`https://eth.oculus.bot/api/createFilter`, data).then((res) => res.data)

  }

  async updateQuery(data) {
    return instance.post(`https://eth.oculus.bot/api/updateFilter`, data).then((res) => res.data)

  }

  async deleteQuery(data) {

    return instance.post(`https://eth.oculus.bot/api/deleteFilter`, data).then((res) => res.data)

  }

  async getStats(data) {
    return instance.post(`https://eth.oculus.bot/api/getStats`, data).then((res) => res.data)
  }

  async getTokens(data) {
    return instance.post(`https://eth.oculus.bot/api/getTokens`, data).then((res) => res.data)
  }

  async generateWallet(data) {
    return instance.post(`https://eth.oculus.bot/api/generateWallet`, data).then((res) => res.data)
  }

  async addAutoBuy(data) {
    return instance.post(`https://eth.oculus.bot/api/addAutoBuy`, data).then((res) => res.data)
  }

  async updateAutoBuy(data) {
    return instance.post(`https://eth.oculus.bot/api/updateAutoBuy`, data).then((res) => res.data)
  }

  async toggleAutoBuy(data) {
    return instance.post(`https://eth.oculus.bot/api/toggleAutoBuy`, data).then((res) => res.data)
  }
  
  async deleteAutoBuy(data) {

    return instance.post(`https://eth.oculus.bot/api/deleteAutoBuy`, data).then((res) => res.data)

  }

  async toggleFilter(data) {
    return instance.post(`https://eth.oculus.bot/api/toggleFilter`, data).then((res) => res.data)
  }
  
  async getBags(data) {
    console.log('data', data)
    return instance.post(`https://eth.oculus.bot/api/getBags`, data).then((res) => res.data)
  }

  async getBagsReal(data) {
    console.log('data', data)
    return instance.post(`https://eth.oculus.bot/api/getBagsReal`, data).then((res) => res.data)
  }

 async sellToken(data) {
    return instance.post(`https://eth.oculus.bot/api/sellToken`, data).then((res) => res.data)
  }
  
  async saveReferrer(data) {
    return instance.post(`https://eth.oculus.bot/api/saveReferrer`, data).then((res) => res.data)
  }

  async preVerify(data) {
    return instance.post(`https://eth.oculus.bot/api/preVerify`, data).then((res) => res.data)
  }

}

export default new FiltersService()
