export const data = {
  getAllItems() {
    const items = []
    this.options.forEach((option) => items.push(...option.items))
    return items
  },
  options: [
    {
      id: 1,
      title: 'Contracts',
      items: [
        {
          id: 1,
          name: 'Verified',
          desc: ''
        },
        {
          id: 2,
          name: 'Renounced',
          desc: ''
        }
      ]
    },
    {
      id: 2,
      title: 'Liquidity',
      items: [
        {
          id: 3,
          name: 'Burned',
          desc: ''
        },
        {
          id: 4,
          name: 'Locked > 1 Month',
          desc: ''
        },
        {
          id: 5,
          name: 'Locked > 3 Months',
          desc: ''
        },
        {
          id: 6,
          name: 'Locked > 6 Months',
          desc: ''
        },
        {
          id: 7,
          name: 'Locked > 1 year',
          desc: ''
        }
      ]
    },


    {
      id: 3,
      title: 'Snipers',
      items: [
        {
          id: 8,
          name: 'Snipers > 5',
          desc: ''
        },
        {
          id: 9,
          name: 'Snipers > 15',
          desc: ''
        },
        {
          id: 10,
          name: 'Snipers > 25',
          desc: ''
        },
        {
          id: 11,
          name: 'Snipers > 50',
          desc: ''
        }
      ]
    },
    {
      id: 3,
      title: 'Funded Origin',
      items: [
        {
          id: 12,
          name: 'Exclude Fixed Float',
          desc: ''
        },
        {
          id: 13,
          name: 'Exclude Binance',
          desc: ''
        },
        {
          id: 14,
          name: 'Exclude Coinbase',
          desc: ''
        },
        {
          id: 15,
          name: 'Exclude MEXC',
          desc: ''
        },
        {
          id: 16,
          name: 'Exclude Non Exchanges',
          desc: ''
        },
      ]
    },
    {
      id: 4,
      title: 'Socials Exists',
      items: [
        {
          id: 17,
          name: 'Telegram',
          desc: ''
        },
        {
          id: 18,
          name: 'Twitter',
          desc: ''
        },
        {
          id: 19,
          name: 'Website',
          desc: ''
        }
      ]
    },
    {
      id: 5,
      title: 'Misc',
      items: [
        {
          id: 20,
          name: 'Entered Trending (BuyBot)',
          desc: 'Token has just entered trending'
        },
        {
          id: 21,
          name: 'Paid Dextools',
          desc: `Dev has made a payment to Dextools`
        }
      ]
    },
    {
      id: 6,
      title: 'Initial Liquidity',
      items: [
        {
          id: 24,
          name: '> 1 ETH',
          desc: 'At least 1 ETH initials'
        },
        {
          id: 25,
          name: '> 2 ETH',
          desc: 'At least 2 ETH initials'
        },
        {
          id: 26,
          name: '> 3 ETH',
          desc: 'At least 3 ETH initials'
        }
      ]
    },
    {
      id: 7,
      title: 'Initial Volume',
      items: [
        {
          id: 29,
          name: '$20K within 5 minutes',
          desc: '20k Within 5 minutes of launch'
        },
        {
          id: 27,
          name: '$50K within 5 minutes',
          desc: '50k Within 5 minutes of launch'
        },
        {
          id: 28,
          name: '100K within 5 minutes',
          desc: '100k Within 5 minutes of launch'
        }
      ]
    },


    {
      id: 8,
      title: 'Checksum',
      items: [
        {
          id: 29,
          name: 'Score of 0',
          desc: 'Fresh Contracts Only'
        },
        {
          id: 30,
          name: 'Score < 5',
          desc: ''
        },
        {
          id: 31,
          name: 'Score < 15',
          desc: ''
        },
        {
          id: 32,
          name: 'Score < 25',
          desc: ''
        },
        {
          id: 33,
          name: 'Score < 50',
          desc: ''
        },
        {
          id: 34,
          name: `Exclude honeypot_low_sell_limit`,
          desc: ''
        },
        {
          id: 35,
          name: `Exclude one_sell_per_block_limit`,
          desc: ''
        },
        {
          id: 36,
          name: `Exclude high_fail_rate`,
          desc: ''
        },
        {
          id: 37,
          name: `Exclude high_siphon_rate`,
          desc: ''
        },
      ]
    },


 {
      id: 9,
      title: 'Experimental',
      items: [
        {
          id: 38,
          name: 'En Passant',
          desc: 'Delayed HP trapper. (God Mode)'
        },
        {
          id: 39,
          name: 'The Sicilian',
          desc: 'Explicit HP trapper. (God Mode)'
        },
      ]
    },
        {
      id: 10,
      title: 'META',
      items: [
        {
          id: 40,
          name: 'ERC 404 Contract'
        },
      
      ]
    },
  ]
}
