import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useActions } from '../../../../hooks/use-actions'
import Button from '../../../ui/Button/Button'
import s from './GenerateWallet.module.scss'
import { AiOutlineCopy } from "react-icons/ai";
import { AiOutlineDelete } from "react-icons/ai";
import { toast } from 'react-toastify'

export default function GenerateWallet() {
    
    const wallets = useSelector((state) => state.modalSlice.wallets)
    const user = useSelector((state) => state.modalSlice.user)
   const [textToCopy, setTextToCopy] = useState('Text to copy');
  const [isCopied, setIsCopied] = useState(false);

  const handleCopyToClipboard = (addy) => {
    // Create a text area element to hold the text to be copied
    const textArea = document.createElement('textarea');
    textArea.value = addy

    // Append the text area element to the document
    document.body.appendChild(textArea);

    // Select the text within the text area and copy it to the clipboard
    textArea.select();
    document.execCommand('copy');

    // Remove the text area element from the document
    document.body.removeChild(textArea);

    // Set the state to indicate that the text has been copied
    setIsCopied(true);

        toast.success('Address copied to the clipboard', {

          position: toast.POSITION.BOTTOM_RIGHT

        })
  }

    const { generateWallet } = useActions()

    const generateNewWallet = async () => {

      let data = {
        
        telegram_id: user.telegram_id,
        hash: user.hash
      
      }

      if (wallets.length > 2) {

        return toast.error(`Max of three wallets`, {
          position: toast.POSITION.BOTTOM_RIGHT,
        })

      } else {

        generateWallet(data)

        toast.success('Wallet Generated', {

          position: toast.POSITION.BOTTOM_RIGHT

        })

      }

    }

    return (

      <div className={s.wallet}>
        <div className={s.title}>Wallets</div>
        <br />      <br />
        {
          wallets.length > 0 

          ? wallets.map((wallet) => (
            <div key={wallet.id} className={s.border}>
              <p className={s.name}>{wallet.name} <span className={s.initial}>{wallet.balance} ETH</span></p>
              <p className={s.address}>{wallet.address} <span className={s.icon}><AiOutlineCopy onClick={() => handleCopyToClipboard(wallet.address)} /></span></p>
            </div>
          )) 

          : (<p className={s.disclaimer}>Generate a wallet to get started</p>)
        }
        <Button className={s.button} onClick={() => generateNewWallet()}>Generate Wallet</Button>
        {
          wallets.length > 0 &&  (<p className={s.disclaimer}>To ensure security, you can only retreive your private keys through our telegram bot with the /keys command</p>)
        }
          
      </div>

    )
}