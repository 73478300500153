import cn from 'clsx'
import React, { useState } from 'react'
import { BsCheck, BsUiChecks } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { useActions } from '../../../../../hooks/use-actions'
import { data } from '../filter-creator.gm.data'
import s from './Option.module.scss'

export default function OptionsGM() {
  return (
    <div className={s.container}>
      <div className={s.title}>Select what works best for you</div>
      <div className={s.accordions}>
        {data.options.map((option, i) => (
          <Option key={i} item={option} />
        ))}
      </div>
    </div>
  )
}

const Option = ({ item }) => {
  const { editQuery } = useActions()
  const query = useSelector((state) => state.modalSlice.query)
  const [isSelectedAll, setIsSelectedAll] = useState(false)

  const selectOrRemoveAll = (items) => {
    if (isSelectedAll) {
      editQuery({
        ...query,
        selected: query.selected.filter(
          (value) => !items.some((item) => item.id === value)
        )
      })
    } else {
      editQuery({
        ...query,
        selected: [
          ...query.selected,
          ...items
            .filter((item) => !query.selected.some((s) => s === item.id))
            .map((item) => item.id)
        ]
      })
    }

    setIsSelectedAll(!isSelectedAll)
  }

  return (
    <div>
      <div>{item.title}</div>
    
      <ul className={s.items}>
        {item.items.map((item) => (
          <OptionItem
            key={item.id}
            item={item}
            query={query}
            setQuery={editQuery}
          />
        ))}
      </ul>
    </div>
  )
}

const OptionItem = ({ query, item, setQuery }) => {
  const addOrRemoveQueryOption = (id) => {
    if (query.selected.some((s) => s === id)) {
      setQuery({
        ...query,
        selected: query.selected.filter((s) => s !== id)
      })
    } else {
      setQuery({
        ...query,
        selected: [...query.selected, id]
      })
    }
  }

  const checked = query.selected.some((s) => s === item.id)


  return (
    <li
      onClick={() => addOrRemoveQueryOption(item.id)}
      className={cn(s.item, checked && s.itemCheck)}
    >
      <div className={cn(s.itemCheckIcon)}>
        {checked && <BsCheck color='white' size={20} />}
      </div>
      <div>
        <div>{item.name}</div>
        <div className={s.itemDesc}>{item.desc}</div>
      </div>
    </li>
  )
}
