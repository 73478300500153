import cn from 'clsx'
import React, { useState } from 'react'
import { BsCheck, BsUiChecks } from 'react-icons/bs'
import { useSelector } from 'react-redux'
import { useActions } from '../../../../../hooks/use-actions'
import s from './Wallet.module.scss'

export default function Wallets() {

  const { wallets } = useSelector((state) => state.modalSlice)


  let gasSettings = {
    
      options: [
        
        {

          id: 1,
          title: 'Wallets',
         
          items: wallets

        },

      ]
  
    }


  return (
    <div className={s.container}>
      <div className={s.accordions}>

        {gasSettings.options.map((option, i) => (
          <Option key={i} item={option} />
        ))}
      </div>
    </div>
  )
}

const Option = ({ item }) => {
  const { editAutoBuyQuery } = useActions()
  const query = useSelector((state) => state.modalSlice.autobuy)

  return (
    <div>


      <div>Wallets</div>
   
      <ul className={s.items}>
        {item.items.map((item) => (
          <OptionItem
            key={item.id}
            item={item}
            query={query}
            setQuery={editAutoBuyQuery}
          />
        ))}
      </ul>


    </div>
  )
}

const OptionItem = ({ query, item, setQuery }) => {

  console.log('query', query)

  const addOrRemoveQueryOption = (id) => {
    if (query.wallets.some((s) => s === id)) {
      setQuery({
        ...query,
        wallets: query.wallets.filter((s) => s !== id)
      })
    } else {
      setQuery({
        ...query,
        wallets: [...query.wallets, id]
      })
    }
  }

  const checked = query.wallets.some((s) => s === item.id)

  return (
    <li
      onClick={() => addOrRemoveQueryOption(item.id)}
      className={cn(s.item, checked && s.itemCheck)}
    >
      <div className={cn(s.itemCheckIcon)}>
        {checked && <BsCheck color='black' size={20} />}
      </div>
      <div>
        <div>{item.name}</div>
      </div>
    </li>
  )
}
